import '../styles/global.scss';
import '../styles/pages/payment.scss';
import 'react-toastify/dist/ReactToastify.css';

import * as React from 'react';
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {addDays, format} from 'date-fns';
import {HeadFC} from "gatsby";
import {
    AppleStoreImage,
    CrownImage,
    GuardImage,
    LoadingGif,
    LogoImage,
    MocupsImage,
    PlayMarketImage,
    StripeImage
} from "../images";
import {useMemo, useRef, useState, useEffect} from "react";
import CardForm from "../components/CardForm";
import {StripeError} from "@stripe/stripe-js/types/stripe-js/stripe";
// import {fbq} from "@hutsoninc/gatsby-plugin-facebook-pixel";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import useAnalytics from "../hooks/useAnalytics";

const PaymentPageOld = () => {
    const {initAnalytics, saveEmail, saveSubType} = useAnalytics();

    useEffect(() => {
        initAnalytics()
    }, []);

    const [eventStatus, setEventStatus] = useState(false);
    useEffect( () => {
        if(!eventStatus){
            // fbq("track", "Lead");
            console.log('fb track lead');
            setEventStatus(true);
        }
    }, [eventStatus]);

    const cardFormRef = useRef();
    const [currentStep, setCurrentStep] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [isCardValid, setCardValid] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('')
    //pk_test_51KoA95Gxzah9dg4Qi3TtrnursgyxrW6YDz0DV1wArKD39N3KXq43XxakjTqCTl6PJH0wmkdbVsTQrSQ8bNQQdYhC00dCv7cvzg
    const stripe = loadStripe('pk_live_51KoA95Gxzah9dg4QawTHGGHiG7NHrUucf3QYIWD69yXAuPARtiheAKsB5Ht3EUmTx5WgxPbrgcX52djjCRq6n6V8000ZFScU5d');

    const onCardValid = (status: boolean) => {
        setCardValid(status);
    }

    const onPayClick = async () => {
        if (currentStep === 1) {
            setLoading(true);
            const card = await (cardFormRef.current as any).onPayClick();
            setLoading(false);

            if (card.error) {
                console.log('Error', card.error);

                toast(card.error.message, {
                    autoClose: 5000,
                    type: 'error',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                })
                return;
            }

            // setPaymentMethod(card.setupIntent.payment_method);
            makePayment(card.setupIntent.payment_method)
        }

        if (currentStep === 0) {
            if (email.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )) {
                saveEmail(email);
                setCurrentStep(currentStep + 1);
                // fbq("track", "Subscribe");
            } else {
                toast('Invalid e-mail address', {
                    autoClose: 5000,
                    type: 'error',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                })
            }

        }

    }

    const makePayment = (cardToken: string, ev?: any) => {
        if (cardToken && email && email.length) {
            setLoading(true);
            axios.post(`https://aeer.app/api/v1/payment/stripe`, {
                cardToken,
                email
            }).then((res) => {
                saveSubType(email)
                // fbq("track", "Purchase");
                if (ev) {
                    ev.complete('success');
                }
                setCurrentStep(currentStep + 1);
            }).catch(() => {
                ev.complete('fail');
                toast('Payment error, please check your credit card data', {
                    autoClose: 5000,
                    type: 'error',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                })
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    const handleTokenCreate = (token: string, ev: any) => {
        makePayment(token, ev);
    }

    const handleCardError = (e: StripeError) => {
        console.log('e', e)
    }

    const endDate = useMemo(() => {
        return format(addDays(new Date(), 7), 'dd MMM yyyy')
    }, [])

    const renderCardForm = () => {
        return (
            <div className="paymentStepContent">
                <div className="paymentHead">
                    <div className="paymentHeadIcon">
                        <img src={GuardImage} alt=""/>
                    </div>
                    <div className="paymentHeadTitle">
                        Finish creating your <br/> account
                    </div>
                </div>
                <div className="paymentNote">
                After chargeless 7-day trial, the subscription cost is just $4.99 per month ($0.16 / day) Cancel your subscription at any time from personal account *
                </div>
                <div className="paymentCardWrapper">
                    <Elements stripe={stripe}>
                        <CardForm onGetCardToken={handleTokenCreate} onCardError={handleCardError} ref={cardFormRef} onValidationChange={onCardValid}/>
                    </Elements>
                </div>

                <div className="paymentCardNotes">
                    Pay just 1$, if you’re ready to start 7-days trial period. This amount automatically returns to
                    your bank account in 24 hours
                </div>

                <div className="paymentStripeNote">
                    <img src={StripeImage} alt=""/>
                    <span>Stripe user authentication</span>
                </div>
            </div>
        )
    }

    const renderSuccess = () => {
        return (
            <>
                <div className="paymentSuccessTitle">
                    Education and <br/>
                    Productivity <br/>
                    in one Platform
                </div>
                <div className="paymentMockups">
                    <img src={MocupsImage} alt=""/>
                </div>
                <div className="stores">
                    <div className="storeItem">
                        <a href="https://apps.apple.com/us/app/aeer-education-productivity/id1630377140"
                           target="_blank">
                            <img src={AppleStoreImage} alt=""/>
                        </a>
                    </div>
                    <div className="storeItem">
                        <a href="#">
                            <img src={PlayMarketImage} alt=""/>
                        </a>
                    </div>
                </div>
                <div className="paymentSuccessNote">
                    Your free trial starts today and ends on <strong>{endDate}</strong>. Your subscription will
                    auto-renew every month
                    after that. You can cancel it at any time up to 24 hours before the next renewal.
                </div>
            </>
        )
    }

    const renderEmailForm = () => {
        return (
            <>
                <div className="emailNote">
                    Leave your email to get access to a personal application for productivity and development of skills
                </div>
                <input onChange={(e) => setEmail(e.target.value)} type="email" placeholder="E-Mail"
                       className="formControl"/>
                <div className="paymentNote">
                After chargeless 7-day trial, the subscription cost is just $4.99 per month ($0.16 / day) Cancel your subscription at any time from personal account *
                </div>
            </>
        )
    }

    const renderStep = useMemo(() => {
        switch (currentStep) {
            case 0: {
                return renderEmailForm();
            }
            case 1: {
                return renderCardForm();
            }
            case 2: {
                return renderSuccess();
            }
            default: {
                return renderCardForm();
            }
        }
    }, [currentStep])


    return (
        <div>
            <div className="paymentWrap">
                <div className="paymentContainer">
                    <div className="paymentContainerTop">
                        <div className="paymentLogo">
                            <img src={LogoImage} width={150} alt=""/>
                        </div>
                        {renderStep}
                    </div>

                    <div className="paymentContainerPressedBottom">
                        {currentStep === 0 && (
                            <button onClick={onPayClick} disabled={isLoading} className="payBtn ga_step_1">
                                <img className="ga_step_1" src={CrownImage} alt=""/>
                                <span className="ga_step_1">Sign up for FREE 7-day trial</span>
                            </button>
                        )}

                        {currentStep === 1 && (
                            <button onClick={onPayClick} disabled={!isCardValid || isLoading} className="payBtn ga_step_2">
                                <img className="ga_step_2" src={CrownImage} alt=""/>
                                <span className="ga_step_2">Sign up for FREE 7-day trial</span>
                            </button>
                        )}

                        {currentStep === 2 && (
                            <div className="paymentFoot">
                                &copy; 2022 AEER Platform 8 THE GREEN STE A DOVER, DE 19901
                                <div>
                                    <a href="https://aeer-platform.com/privacy/" target="_blank">Privacy
                                        Policy</a> &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;<a
                                    href="https://aeer-platform.com/terms/" target="_blank">Terms of Use</a>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {isLoading && (
                <div className="loaderWrap">
                    <div className="loader">
                        <img width={44} src={LoadingGif} alt=""/>
                    </div>
                </div>
            )}
            <ToastContainer/>
        </div>
    )
}

export default PaymentPageOld;

export const Head: HeadFC = () => (
    <>
        <title>Checkout - AEER PLATFORM</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
    </>
);

