import * as React from 'react';
import {CardElement, useElements, useStripe, PaymentRequestButtonElement} from "@stripe/react-stripe-js";
import {StripeCardElementChangeEvent, StripeCardElementOptions,} from "@stripe/stripe-js";
import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {StripeError} from "@stripe/stripe-js/types/stripe-js/stripe";
import axios from "axios";

const cardElemOptions: StripeCardElementOptions = {
    hideIcon: true, hidePostalCode: true, style: {
        base: {
            color: '#fff'
        }
    }
}

type Props = {
    onValidationChange: (status: boolean) => void,
    onCardError: (err: StripeError) => void
    onGetCardToken: (token: string, ev: any) => void
}

const CardFormComponent = forwardRef(({onValidationChange, onCardError, onGetCardToken}: Props, ref) => {
    const stripeAPI = useStripe();
    const elements = useElements();
    const [token, setToken] = useState<string | undefined>(undefined);
    const [paymentMethod, setPaymentMethod] = useState(null);

    useEffect(() => {
        axios.get(`https://aeer.app/api/v1/payment/stripe/intent`).then((res) => {
            setToken(res.data);
        }).catch(() => {
        });
    }, [])

    useEffect(() => {

        const pr = stripeAPI?.paymentRequest({
            country: 'US',
            currency: 'usd',
            total: {
                label: 'PRO plan',
                amount: 100
            },
            requestPayerName: true,
            requestPayerEmail: true,
        });

        pr?.canMakePayment().then((res) => {
            if (res) {
                setPaymentMethod(pr as any);

                pr?.on('paymentmethod', (ev) => {
                    if (onGetCardToken && ev?.paymentMethod?.id) {
                        onGetCardToken(ev.paymentMethod.id, ev)
                    }
                })
            }
        }).catch(err => {
            console.log('err', err)
        })
    }, [stripeAPI])

    const onCardChange = (e: StripeCardElementChangeEvent) => {
        onValidationChange(e.complete)
    }

    useImperativeHandle(ref, () => ({
        async onPayClick() {
            if (stripeAPI && elements && token) {
                try {
                    const res = await stripeAPI?.confirmCardSetup(token, {
                        payment_method: {
                            card: elements.getElement(CardElement) as any
                        }
                    });
                    return res;
                } catch (e) {
                    return e;
                }
            }
        }
    }))

    return (
        <>
            <div className="paymentCardForm">
                <CardElement onChange={onCardChange}
                             options={cardElemOptions}/>
            </div>
            <div className="paymentsButtons">
                {paymentMethod && (
                    <PaymentRequestButtonElement options={{paymentRequest: paymentMethod, style: {
                        paymentRequestButton: {
                            theme: "light"
                        }
                        }}}/>
                )}
            </div>
        </>
    );
})

export default CardFormComponent;
